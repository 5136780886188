<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <div class="col-11">KAFT Affiliate Programı</div>
            <div class="col-1">
            </div>
          </div>
        </CCardHeader>
        <CCardBody>
          <CRow style="justify-content: center">
            <CCol col="3">
              <CInput
                description="Başlangıç Tarihi"
                type="date"
                v-model="startDate"
                horizontal
              />
            </CCol>
            <CCol col="3">
              <CInput
                description="Bitiş Tarihi"
                type="date"
                v-model="endDate"
                horizontal
              />
            </CCol>
            <CCol col="3">
              <CSelect
                description="Affiliate User"
                placeholder="Seçiniz"
                :options="affiliateUserList"
                type="text"
                horizontal
                :value.sync="userId"
              />
            </CCol>
            <CCol col="3">
              <CSelect
                  description="Döviz Tipi"
                  placeholder="Seçiniz"
                  :options="activeCurrencies"
                  type="text"
                  horizontal
                  :value.sync="currencyId"
              />
            </CCol>
            <CButton size="lg" color="success" @click="filterAffiliate(startDate, endDate, userId, currencyId)">Uygula</CButton>&nbsp;&nbsp;

          </CRow>
          <CCol col="12">
            <CDataTable
              :items="affiliates"
              :fields="fields"
              :loading="loading"
              :items-per-page="10"
              pagination
              clickable-rows
              hover
              v-on:refresh="filterAffiliate(startDate,endDate,userId, currencyId);"
            >
            <template #orderCreateTime="item">
              <td> {{ item.orderCreateTime && dateFormat(item.orderCreateTime) }}</td>
            </template>
              <template slot="footer" v-if="this.totalAmount >0">
                <strong> Toplam Tutar: {{ this.totalAmount }} </strong>
              </template>
            </CDataTable>
          </CCol>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import moment from "moment";

export default {
  name: 'Affiliate',
  data() {
    return {
      fields: [
        {key: 'orderId', label: 'Sipariş No', _style: 'font-size:12px'},
        {key: 'orderCreateTime', label: 'Sipariş Zamanı', _style: 'font-size:12px'},
        {key: 'orderStatus', label: 'Sipariş Durumu', _style: 'font-size:12px'},
        {key: 'directClick', label: 'Direct Click', _style: 'font-size:12px'},
        {key: 'numItems', label: 'Num Items', _style: 'font-size:12px'},
        {key: 'amount', label: 'Tutar', _style: 'font-size:12px'},
        {key: 'currencyCode', label: 'Döviz Cinsi', _style: 'font-size:12px'},
        {key: 'refrer', label: 'Refrer', _style: 'font-size:12px'}
      ],
      userId: "",
      currencyId: "",
      startDate: "",
      endDate: "",
      totalAmount: 0
    }
  },
  computed: {
    affiliateUserList: function(){
      let data = []
      this.$store.getters.affiliateUserList.map(e => data.push({label: e.name, value: e.id}))
      return data
    },
    activeCurrencies: function() {
        let data = []
        this.$store.getters.activeCurrencies.map(r => data.push({value:r.id, label: r.name}))
        return data
    },
    affiliates: function(){
      return this.$store.getters.affiliates
    },
    loading: function(){
      return this.$store.getters.reportLoading
    },
    totalCount: function(){
      let totalCount = 0
      this.$store.getters.affilates.map(a => {
        totalCount += a.amount
      })
      return totalCount
    }
  },
  methods: {
    dateFormat(date){
      return moment(date).format('DD.MM.YYYY HH:mm:ss')
    },
    
    async filterAffiliate(startDate, endDate, userId, currencyId) {
      let params = {
        "startDate":  moment(startDate).format('YYYY-MM-DDTHH:mm:ss:SSZ'),
        "endDate":  moment(endDate).format('YYYY-MM-DDTHH:mm:ss:SSZ'),
        "userId": userId,
        "currencyId": (currencyId !== '' && currencyId !== undefined) ? currencyId : 0
      }
      await this.$store.dispatch('affiliate_list', params)
    },
  },

  created() {
    var today = new Date();
    this.endDate = moment(today).format('YYYY-MM-DD');
    this.startDate = moment(today).format('YYYY-MM-DD');
    this.$store.dispatch('affiliateUser_list')
    this.$store.dispatch('activeCurrency_list')
    this.filterAffiliate(this.startDate,this.endDate,this.userId);

  }
}
</script>
